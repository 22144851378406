class WebSpeechAPIListeningDriver {
	constructor(options) {
		options = options || {};

		this.onResult = (text, confidence) => { return false; }
		this.onSpeechEnd = () => { return false; }
		this.onFinalResult = (text) => { return false; }

		this._initializeRecognizer(options);
	}

	_initializeRecognizer(options) {
		const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
		const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
		const SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

		const recognizer = new SpeechRecognition();
		this.recognizer = recognizer;
		recognizer.continuous = true;
		recognizer.lang = navigator.language || 'en-US';
		recognizer.interimResults = true;
		recognizer.maxAlternatives = 1;

		this._speechTimeout = options.speechInputTimeout || 750;
		this._timeoutHandle = null;

		recognizer.onerror = (event) => {
			console.error(`STT: Error detected: ${event.error}`);
			this._isDone = true;
		};
		recognizer.onnomatch = (event) => {
			console.warn('STT: Unknown word detected');
		};
		recognizer.onresult = (event) => {
			console.debug('STT: result');
			let isFinal = false;
			const transcript = Array.from(event.results).map(r => {
				if (r[0].isFinal) { isFinal = true; }
				return r[0].transcript;
			}).join(' ');
			console.debug(`STT: result -> "${transcript}"`);
			this.onResult(transcript);
			if (isFinal) {
				this._isDone = true;
			}

			clearTimeout(this._timeoutHandle);
			if (this._isDone) {
				this.onFinalResult(transcript);
			} else {
				this._timeoutHandle = setTimeout(() => {
					this.onFinalResult(transcript);
				}, this._speechTimeout);
			}
		};
		recognizer.onstart = () => {
			console.debug('STT: start');
		}
		recognizer.onend = () => {
			console.debug('STT: end');
		}
		recognizer.onaudiostart = () => {
			console.debug('STT: audio start');
		}
		recognizer.onspeechstart = () => {
			console.debug('STT: speech start');
		}
		recognizer.onaudioend = () => {
			console.debug('STT: audio end');
			this.onSpeechEnd();
		};
		recognizer.onspeechend = () => {
			console.debug('STT: speech end');
			this.onSpeechEnd();
		};
	}

	start(resultCallback, speechEndCallback) {
		this._isDone = false;
		this.onResult = resultCallback;
		this.onSpeechEnd = speechEndCallback;
		this.recognizer.start();
	}

	stop() {
		this.recognizer.stop();
		setTimeout(() => {
			this._isDone = true;
		}, 500);
	}
}

class WebSpeechAPISpeakingDriver {
	constructor(options) {
		this._initializeSpeaker(options);
	}

	_initializeSpeaker(options) {
		this._speaker = window.speechSynthesis || window.webkitSpeechSynthesis || new window.SpeechSynthesis();
	}

	speak(text, callback) {
		const utterance = new SpeechSynthesisUtterance(text);
		utterance.addEventListener('end', () => { callback(); });
		utterance.addEventListener('error', () => { callback(); });
		this._speaker.speak(utterance);
	}

	stop() {
		this._speaker.cancel();
	}
}

export { WebSpeechAPIListeningDriver, WebSpeechAPISpeakingDriver };