const CONTENT_TYPE_JSON = 'application/json; charset=utf-8';
const defaultApiAjaxArgs = { dataType: 'json', method: 'POST' };
class XhrError extends Error {
	constructor(jqXHR, textStatus, errorThrown) {
		super(errorThrown);
		this.errorThrown = errorThrown;
		this.jqXHR = jqXHR;
		this.name = 'XhrError';
		this.textStatus = textStatus;
	}
}
class AiService {
	constructor(options) {
		this._rootUrl = options?.settings?.aiService?.url;
		this._apiUrl = this._rootUrl + 'api/v0/';
		this._viewerModel = options.viewerModel;
	}

	/**
	 * Returns a Promise with the audio URL for the TTS.
	 * @param {string} text
	 * @param {string=} voice
	 * @returns {Promise<string>} The URL
	 */
	getTtsAudioUrl(text, voice) {
		const params = {
			text: text
		};
		if (voice) {
			params.voice = voice;
		}
		return new Promise((resolve, reject) => {
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'get-tts',
					id: Math.random(),
					params: params
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data?.result?.id) {
					reject(jqXhr, status);
				} else {
					resolve(`${this._rootUrl}text-to-speech/${data.result.id}.mp3`);
				}
			});
		});
	}

	getChat() {
		return new Promise((resolve, reject) => {
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'get-chat',
					id: Math.random(),
					params: {
						identityId: window.page?.session?.identityId,
						roomId: this._viewerModel.chatId
					}
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data) {
					reject(jqXhr, status);
				} else {
					resolve(data);
				}
			});
		});
	}

	getChatSummary() {
		return new Promise((resolve, reject) => {
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'summarize-chat',
					id: Math.random(),
					params: {
						identityId: window.page?.session?.identityId,
						roomId: this._viewerModel.chatId
					}
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data) {
					reject(jqXhr, status);
				} else {
					resolve(data);
				}
			});
		});
	}

	recordAnswer(answer, rawAnswer, questionId, userMessage) {
		const params = {
			identityId: window.page?.session?.identityId,
			projectGuid: this._viewerModel?._projectGuid,
			roomId: this._viewerModel.chatId,
			answer: answer,
			rawAnswer: rawAnswer,
			questionId: questionId,
			userMessage: userMessage
		};
		return new Promise((resolve, reject) => {
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'record-answer',
					id: Math.random(),
					params: params
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data) {
					reject(jqXhr, status);
				} else {
					resolve(data?.result);
				}
			});
		});
	}

	recordProblem(messageId, problem /* string then there is a problem, otherwise not */) {
		return new Promise((resolve, reject) => {
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'record-problem',
					id: Math.random(),
					params: {
						messageId: messageId,
						problem: problem
					}
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data) {
					reject(jqXhr, status);
				} else {
					resolve(data?.result);
				}
			});
		});
	}

	recordQuestion(question) {
		return new Promise((resolve, reject) => {
			const params = {
				identityId: window.page?.session?.identityId,
				projectGuid: this._viewerModel?._projectGuid,
				roomId: this._viewerModel.chatId,
				question: question
			};
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'record-question',
					id: Math.random(),
					params: params
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data) {
					reject(jqXhr, status);
				} else {
					resolve(data?.result);
				}
			});
		});
	}

	sendMessage(params) {
		return new Promise((resolve, reject) => {
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'send-message',
					id: Math.random(),
					params: params
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data) {
					reject(jqXhr, status);
				} else {
					resolve(data?.result);
				}
			});
		});
	}
}

export { AiService }
