import Editor from '@toast-ui/editor';

class EditMode {
	constructor(options) {
		this._api = options?.api;
		this._model = options?.model;
	}

	toggle(toggle) {
		if (toggle) {
			this._hookupRequestDocumentsButton()
				._hookupChatLeadCaptureButton()
				._hookupDialogSaveButtons();
			$('.lead-capture-dialog').find('button.submit,input').prop('disabled', true); // Disable the "submit" buttons in the dialog.
		} else {
			this._disableEditModeAndSave();
		}
	}

	_disableEditModeAndSave() {
		$('.edit-mode-only').remove();

		$('.edit-mode-editable').off('focusout').each((_i, el) => {
			$(el).attr('contenteditable', false).text($(el).attr('originalText')).removeAttr('originalText');
		})
		$('.request-access-button-section').addClass('hideIfEditMode').toggle(this._model.isRequestAccessButtonEnabled);
		$('#RequestAccessDialog').dialog('close');
		$('#ChatLeadCaptureDialog').dialog('close');
		$('#ChatLeadCaptureDialogContent,#RequestAccessDialogProjectContent').each((_i, el) => {
			// Swap the editor content to the read-only div.
			const $editor = $(el).data('editor');
			const $inner = $(el).data('oldInner');
			if ($editor) {
				let html = $editor.getHTML();
				$(el).removeData('editor').removeData('oldInner').append($inner);
				$inner.html(html).show();
			}
		});
		$('.toastui-editor-defaultUI').remove(); // Remove the editor.
		this._updateRequestAccess(this._model.isRequestAccessButtonEnabled);
		$('.lead-capture-dialog').find('button.submit,input').prop('disabled', false);
		return this;
	}

	_hookupChatLeadCaptureButton() {
		$('.edit-me-checkbox.chat-lead-capture input').prop('checked', this._model.isChatLeadCaptureEnabled);

		$('.edit-me-checkbox.chat-lead-capture').off('click').on('click', (evt) => {
			const checkbox = ('INPUT' === evt.target.tagName) ? evt.target : $(evt.target).closest('.edit-me-checkbox').find('input').get(0);
			let isChecked = checkbox.checked;
			if ('INPUT' === evt.target.tagName) {
				isChecked = !isChecked;
			} else if ('I' === evt.target.tagName) {
				$('#ChatLeadCaptureDialog').dialog('open');
				isChecked = false;
			}
			if (!isChecked) {
				checkbox.checked = true;
				$('#ChatLeadCaptureDialogContent').each((_i, el) => {
					const $inner = $(el).find('#ChatLeadCaptureDialogContentInner');
					$inner.hide();
					if ($(el).find('.toastui-editor-defaultUI').length === 0) {
						const $editor = new Editor({
							el: document.querySelector('#ChatLeadCaptureDialogContent'),
							height: '250px',
							hideModeSwitch: true,
							initialEditType: 'wsywig',
							previewStyle: 'vertical',
							toolbarItems: [['bold', 'italic', 'ul', 'ol']]
						});
						const md = new showdown.Converter().makeMarkdown($inner.html()).replaceAll('<!-- -->', '');
						$editor.setMarkdown(md);
						$('#ChatLeadCaptureDialogContent').data('oldInner', $inner).data('editor', $editor);
					}
					$(el).find('.toastui-editor-defaultUI').off('focusout').on('focusout', () => {
						this._updateChatLeadCapture(true);
					});
				});
				$('#ChatLeadCaptureDialog').dialog('open');
			} else {
				checkbox.checked = false;
				$('#ChatLeadCaptureDialog').dialog('close');
			}
			this._updateChatLeadCapture(!isChecked);
		});
		return this;
	}

	_hookupDialogSaveButtons() {
		let $dialog1 = $('#ChatLeadCaptureDialog');
		$dialog1.find('button.save').off('click').on('click', () => {
			this._updateChatLeadCapture(true);
			$dialog1.dialog('close');
		});
		let $dialog2 = $('#RequestAccessDialog');
		$dialog2.find('button.save').off('click').on('click', () => {
			this._updateRequestAccess(true);
			$dialog2.dialog('close');
		});

		return this;
	}

	_hookupRequestDocumentsButton() {
		$('.edit-me-checkbox.request-access').off('click').on('click', (evt) => {
			// section (checkbox - text - edit icon)
			// trying to figure out what portion of that was clicked.
			// checkbox and text will change selection; edit icon will force checked=true and open dialog
			const checkbox = ('INPUT' === evt.target.tagName) ? evt.target : $(evt.target).closest('.edit-me-checkbox').find('input').get(0);
			let isChecked = checkbox.checked;
			if ('INPUT' === evt.target.tagName) {
				isChecked = !isChecked;
			} else if (evt.target.tagName === 'I') {
				$('#RequestAccessDialog').dialog('open');
				isChecked = false;
			}
			if (!isChecked) {
				checkbox.checked = true;
				$('.request-access-button-section').removeClass('hideIfEditMode').show();
				$('.request-access-button-text').off('focusout').on('focusout', () => {
					$('.request-access-button-text').each((_i, el) => {
						$(el).attr('originalText', $(el).text());
					});
					this._updateRequestAccess(true); // Save the changes to the "Additional documents available" section in the drawer, on focusout.
				});
				$('.request-access-button-text').each((_i, el) => {
					// Mark it contenteditable and save off the original text.
					$(el).addClass('edit-mode-editable').attr('contenteditable', true).attr('originalText', $(el).text()).triggerHandler('focus');
				});
				$('#RequestAccessDialogProjectContent').each((_i, el) => {
					const $inner = $(el).find('#RequestAccessDialogProjectContentInner');
					$inner.hide();
					if ($(el).find('.toastui-editor-defaultUI').length === 0) {
						const $editor = new Editor({
							el: document.querySelector('#RequestAccessDialogProjectContent'),
							height: '250px',
							hideModeSwitch: true,
							initialEditType: 'wsywig',
							previewStyle: 'vertical',
							toolbarItems: [['heading', 'bold', 'italic', 'hr', 'quote', 'ul', 'ol', 'image', 'link']]
						});
						const md = new showdown.Converter().makeMarkdown($inner.html()).replaceAll('<!-- -->', ''); // <!-- --> gets put in there by showdown for some reason.
						$editor.setMarkdown(md);
						$('#RequestAccessDialogProjectContent').data('oldInner', $inner).data('editor', $editor);
						$(el).find('.toastui-editor-defaultUI').off('focusout').on('focusout', () => {
							this._updateRequestAccess(true); // Save when leaving focus.
						});
					}
				});
			} else {
				checkbox.checked = false;
				$('.request-access-button-section').addClass('hideIfEditMode');
				$('.request-access-button-text').each((_i, el) => {
					$(el).removeClass('edit-mode-editable').attr('contenteditable', false).text($(el).attr('originalText')).removeAttr('originalText').off('focusout');
				});
			}
			this._updateRequestAccess(!isChecked);
		});
		$('.edit-me-checkbox.request-access input').prop('checked', !this._model.isRequestAccessButtonEnabled).trigger('click');
		return this;
	}

	/**
	 * Update the local model and remote database relating to ChatLeadCapture on this project
	 * @param {boolean} enabled
	 * @returns
	 */
	_updateChatLeadCapture(enabled) {
		const update = { ChatLeadCapture: { Enabled: enabled, DialogMarkdown: '' } };
		if (enabled) {
			let ed = $('#ChatLeadCaptureDialogContent').data('editor');
			update.ChatLeadCapture.DialogMarkdown = ed ? ed.getMarkdown() :
				new showdown.Converter().makeMarkdown($('#ChatLeadCaptureDialogContentInner').html());
		}
		this._api.updateProjectExtraJson(this._model._projectId, update);
		this._model._project.ExtraJson = $.extend({}, this._model._project.ExtraJson, update);
		return this;
	}

	/**
	 * Update the local model and remote database relating to RequestAccess (dialog/button) on this project
	 * @param {boolean} enabled
	 * @returns
	 */
	_updateRequestAccess(enabled) {
		const update = { RequestAccess: { Enabled: enabled, ButtonText: '', DialogMarkdown: '' } };
		if (enabled) {
			update.RequestAccess.ButtonText = $('.request-access-button-text').eq(0).text();
			let ed = $('#RequestAccessDialogProjectContent').data('editor');
			update.RequestAccess.DialogMarkdown = ed ? ed.getMarkdown() :
				new showdown.Converter().makeMarkdown($('#RequestAccessDialogProjectContentInner').html());
		}
		this._api.updateProjectExtraJson(this._model._projectId, update);
		this._model._project.ExtraJson = $.extend({}, this._model._project.ExtraJson, update);
		return this;
	}
}

export { EditMode }
