import { ContentId } from '../models/content-id.es12.js';
import { MulticastEventHandler } from '../utils/multicast-event-handler.es13.js';

const CONTENT_ID_CHANGED_EVENT_TYPE = 'content-id-changed';
const ROOM_CHANGED_EVENT_TYPE = 'room-changed';

// Shim exposing legacy dashboard global variables in a model class and migrating towards an encapsulated model.
// Single room (at a time).
class ChatModel {
	/**
	 * @param {object} options
	 * @param {ContentId=} options.contentId The content ID relating to the open room (used when enterring that room).
	 * @param {number=} options.roomId
	 * @param {object} options.settings
	 * @param {ChatLegacyShim} options.shim
	 */
	constructor(options) {
		this._eventHandler = new MulticastEventHandler();
		this._contentId = options?.contentId || new ContentId();
		this._roomId = options?.roomId;
		this._settings = options.settings || {};
		this._shim = options?.shim;
	}

	get callosumChannelId() {
		const chatRoomIdFormat = this._settings.Chat.RoomIdFormat;
		return chatRoomIdFormat.replace('{0}', this._roomId);
	}

	/**
	 * @type {ContentId}
	 */
	get contentId() {
		return this._contentId;
	}

	set contentId(val) {
		const changed = (this._contentId !== val);
		this._contentId = val?.isValid ? val : new ContentId();
		this._shim.setContentId(this._contentId.guid || this._contentId.id, this._contentId.id,
			this._contentId.type);
		changed && this._eventHandler.trigger(CONTENT_ID_CHANGED_EVENT_TYPE, this._contentId);
		return val;
	}

	/**
	 * @type {object}
	 */
	get currentSceneChatParameters() {
		return this._shim.currentSceneChatParameters;
	}

	get myDisplayName() {
		return this._shim.myDisplayName;
	}

	get myParticipantId() {
		return this._shim.myParticipantId;
	}

	/**
	 * @type {number=}
	 */
	get roomId() {
		return this._roomId;
	}

	set roomId(val) {
		const changed = (this._roomId !== val);
		this._roomId = +val || null;
		changed && this._eventHandler.trigger(ROOM_CHANGED_EVENT_TYPE, val);
		return val;
	}

	addOutgoingMessage(chatMessageShim) {
		return this._shim.addOutgoingMessage(chatMessageShim);
	}

	addReceivedMessage(chatMessageShim) {
		this._shim.addReceivedMessage(chatMessageShim);
		return this;
	}

	addReceivedRespondableMessage(chatMessageShim) {
		this._shim.addReceivedRespondableMessage(chatMessageShim);
		return this;
	}

	newChatMessage(chatMessageShim) {
		return this._shim.newChatMessage(chatMessageShim);
	}

	/**
	 * Register an event handler for when the content ID has changed.
	 * @param {function} handler
	 * @param {object=} options
	 * @param {boolean=} [options.synchronous=false]
	 * @returns {ChatModel}
	 */
	onContentIdChanged(handler, options) {
		this._eventHandler.on(CONTENT_ID_CHANGED_EVENT_TYPE, handler, options);
		return this;
	}

	/**
	 * Register an event handler for when the room has changed.
	 * @param {function} handler
	 * @param {object=} options
	 * @param {boolean=} [options.synchronous=false]
	 * @returns {ChatModel}
	 */
	onRoomChanged(handler, options) {
		this._eventHandler.on(ROOM_CHANGED_EVENT_TYPE, handler, options);
		return this;
	}
}

export { ChatModel }
