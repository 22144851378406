/**
 * @file Class for managing multicast events (synchronous callbacks or subsequent main loops).
 */

/**
 * Manages attaching/detaching from an event handler (either synchronous callbacks or subsequent main loops).
 * @class
 */
class MulticastEventHandler {
	constructor() {
		// string eventName => {_handler, _async}[]
		this._eventHandlers = new Map();
	}

	/**
	 * Detach an event handler.
	 * @param {string=} events - Space delimited list of events to remove handler from. Defaults to all events
	 * unless at least 1 is specified.
	 * @param {function=} handler - Handler to remove. Defaults to all handlers.
	 * @returns {MulticastEventHandler} - this
	 * @throws {TypeError}
	 */
	off(events, handler) {
		let eventTypes = [];
		if ('string' === typeof (events)) {
			eventTypes = events.split(' ');
		} else if ((null !== typeof (events)) && ('undefined' !== typeof (events))) {
			throw new TypeError('Invalid events specified in off().');
		}
		if (!eventTypes.length) {
			eventTypes = Array.from(this._eventHandlers.keys);
		}
		eventTypes.forEach((eventType) => {
			if (!handler) {
				this._eventHandlers.delete(eventType);
			} else {
				let wrappers = this._eventHandlers.get(eventType);
				// Don't mutate the handler array, so that off can be called safely during a trigger.
				wrappers = wrappers.filter((wrapper) => wrapper._handler !== handler);
				if (!wrappers.length) {
					this._eventHandlers.delete(eventType);
				} else {
					this._eventHandlers.set(eventType, wrappers);
				}
			}
		});
		return this;
	}

	/**
	 * Attach an event handler. Default is that when this event is triggered, *handler* will be called
	 * asyncronously. Specify options.synchronous = true to receive a callback synchronously. Callbacks are
	 * called (or events triggered) in the order in which they are attached via calls to *on*.
	 * @param {string} events - Space delimited list of events to attach handler to.
	 * @param {function} handler
	 * @param {object=} options
	 * @param {boolean=} [options.synchronous=false] - If handler should be called synchronously.
	 * @returns {MulticastEventHandler} - this
	 */
	on(events, handler, options) {
		if (('string' !== typeof (events)) || ('function' !== typeof(handler))) {
			return this;
		}
		const eventTypes = events.split(' ');
		if (!eventTypes.length) {
			return this;
		}
		const wrapper = {
			_async: !(options?.synchronous),
			_handler: handler
		};
		eventTypes.forEach((eventType) => {
			// No mutation makes on() callable in a handler.
			let wrappers = this._eventHandlers.get(eventType);
			if (!wrappers) {
				wrappers = [wrapper];
			} else {
				wrappers = wrappers.concat([wrapper]);
			}
			this._eventHandlers.set(eventType, wrappers);
		});
		return this;
	}

	/**
	 * Trigger any attached event handlers.
	 * @param {string} eventType - singular event type to trigger.
	 * @param {...any=} args
	 * @returns {MulticastEventHandler} this
	 */
	trigger(eventType, ...args) {
		const wrappers = this._eventHandlers.get(eventType);
		wrappers?.forEach((wrapper) => {
			try {
				if (wrapper._async) {
					setTimeout(() => { wrapper._handler.apply(null, args); }, 0);
				} else {
					wrapper._handler.apply(null, args);
				}
			} catch (e) {
				console.error(e);
			}
		});
		return this;
	}
}

export { MulticastEventHandler }
