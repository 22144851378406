export function getCurrentListingId() {
	return EveryScape?.SyncV2?.CurrentState?.self?.currentPlace?.Id;
}

export function getCurrentListingContentItems() {
	return EveryScape?.SyncV2?.CurrentState?.self?.currentPlace?.ListingContentItems;
}

export function getCurrentListingUrls() {
	return EveryScape?.SyncV2?.CurrentState?.self?.currentPlace?.ListingUrls;
}

export function getAuthToken() {
	return getSecurityToken();
}

export function getCurrentOrDefaultDisplayName() {
	const idName = window.page.session?.contact?.DisplayName;
	if (idName) {
		return idName;
	}
	const self = EveryScape.SyncV2.CurrentState?.self || {};
	const pId = self.participantId || "";
	return self.displayName.trim() ? self.displayName.trim() : `Guest_${pid}`;
}

export function getCurrentTelemetry() {
	const self = EveryScape.SyncV2.CurrentState?.self;
	return {
		content: {
			contentPath: self?.content ?? 'overlay://default'
		},
		viewerPosition: self?.position ?? []
	}
}