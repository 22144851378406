import { WebSpeechAPIListeningDriver as STTDriver } from './driver-web-speech-api.es13'


class SpeechToText {
	constructor(options) {
		this._beepOnUrl = 'https://oliostorage.blob.core.windows.net/beeps/beep-on.mp3';
		this._beepOffUrl = 'https://oliostorage.blob.core.windows.net/beeps/beep-off.mp3';
		this._transcriptionOutputElement = options.transcriptionOutputElement || document.createElement('DIV');
		this._transcriptionDoneCallback = options.transcriptionDoneCallback || function(text) { return false };
		this._driver = new STTDriver();
		this._driver.onFinalResult = (text) => { this._transcriptionDoneCallback(text) };
		this._beepOnElement = document.createElement('audio');
		this._beepOnElement.src = this._beepOnUrl;
		this._beepOffElement = document.createElement('audio');
		this._beepOffElement.src = this._beepOffUrl;
	}

	_updateResult(text) {
		$(this._transcriptionOutputElement).val(text);
	}

	record() {
		this._recording = true;
		this._beepOnElement.play();
		this._driver.start(
			(text) => { this._updateResult(text) },
			() => { this.stop() }
		);
	}

	stop() {
		this._driver.stop();
		if (this._recording) {
			this._beepOffElement.play();
		}
		this._recording = false;
	}

	get transcriptionOutputElement() {
		return this._transcriptionOutputElement;
	}

	set transcriptionOutputElement(element) {
		return this._transcriptionOutputElement = element;
	}

	get transcriptionDoneCallback() {
		return this._transcriptionDoneCallback;
	}

	set transcriptionDoneCallback(fn) {
		this._transcriptionDoneCallback = fn;
	}
}

export { SpeechToText };