class ContentType {
	static get CAMPAIGN() {
		return 'campaign';
	}

	static get LISTING() {
		return 'listing';
	}

	static get PROJECT() {
		return 'project';
	}

	static isValid(val) {
		return ['campaign', 'listing', 'project'].includes(val?.toLowerCase());
	}

	static normalize(val) {
		let tmp = val?.toLowerCase();
		return ContentType.isValid(tmp) ? tmp : null;
	}
}

/**
 * @classdesc Immutable unique identifier for a project/campaign/listing/etc.
 */
class ContentId {
	/**
	 * @param {object=} options
	 * @param {string=} options.guid
	 * @param {number=} options.id
	 * @param {type=} options.type
	 */
	constructor(options) {
		this._guid = ContentId.normalizeGuid(options?.guid);
		this._id = +(options?.id) || null;
		this._type = ContentType.normalize(options?.type);
	}

	/**
	 * Build a ContentId from a numeric _or_ guid ID and string type.
	 * @param {number|string} id
	 * @param {string} type
	 */
	static fromIdAndType(id, type) {
		const builder = { guid: null, id: null, type: type };
		if (('string' === typeof(id)) && (id?.length >= 32)) {
			const tmp = id.toLowerCase().replaceAll(/[^0-9a-f]/g, '');
			if (32 === tmp.length) {
				builder.guid = tmp;
			}
		} else {
			let i = parseInt(id);
			if (!isNaN(i) && (i > 0)) {
				builder.id = i;
			}
		}
		return new ContentId(builder);
	}

	static normalizeGuid(val) {
		const tmp = val ? ('' + val).toLowerCase().replaceAll(/[^0-9a-f]/g, '') : null;
		return (32 === tmp?.length) ? tmp : null;
	}

	/**
	 * @type {string=} Either the GUID or ID, in string form.
	 */
	get anyId() {
		let str = this._guid || this._id;
		return str ? ('' + str) : null;
	}

	/**
	 * @type {string=}
	 */
	get guid() {
		return this._guid;
	}

	/**
	 * Numeric ID or null.
	 * @type {number=}
	 */
	get id() {
		return this._id;
	}

	get isValid() {
		return !!(this._guid || (this._id && this._type));
	}

	/**
	 * @type {string=}
	 */
	get type() {
		return this._type;
	}

	clone() {
		return new ContentId({ guid: this._guid, id: this._id, type: this._type });
	}

	toString() {
		const parts = [];
		if (this._type) { parts.push(this._type); }
		if (this._id) { parts.push(this._id); }
		if (this._guid) { parts.push(this._guid); }
		return parts.join(':');
	}
}

export { ContentId, ContentType }
