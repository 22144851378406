import { default as Cookies } from 'js-cookie';

const REQUEST_ACCESS_FORM_VALIDATORS = {
	email: (val) => { return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val); },
	name: (_val) => { return true; },
	phone: (val) => { return !val || val.length > 6; }
};

/**
 * Client-side-stored contact info.
 * @typedef {object} LeadContact
 * @property {string=} email
 * @property {string=} name
 * @property {string=} phone
 */

/**
 * Client-side-stored project info.
 * @typedef {object} LeadProject
 * @property {number=} reqAccess timestamp in ms-since-epoch of last document request for this project.
 */

const COOKIE_NAMES = {
	PROJECTS: 'Lead:Projects' // JSON, {$projectNumericId: LeadProject}
};

/**
 * @class
 * @classdesc Contact-information stored client-side, for reuse, for non-logged-in users.
 *     Client-side information about what projects have been interacted with.
 *     Stored in cookies, rather than local storage, due to use of multiple domains.
 */
class LeadModel {
	/**
	 * @param {object=} options
	 * @param {object=} options.settings
	 * @param {string=} options.settings.CookieDomain
	 */
	constructor(options) {
		this._cookieDomain = options?.settings?.CookieDomain;
		this._cookieDomainArg = this._cookieDomain ? { domain: this._cookieDomain } : {};
		/** @private @property {object.<number, LeadProject>} _leadProjects */
		this._leadProjects = {};
		this._session = options?.session;
	}

	/**
	 * @returns {LeadContact}
	 */
	get leadContact() {
		return {
			name: this._session?.contact?.DisplayName,
			email: this._session?.contact?.EmailAddress,
			phone: this._session?.contact?.PhoneNumber
		};
	}

	get validators() {
		return REQUEST_ACCESS_FORM_VALIDATORS;
	}

	/**
	 * DEPRECATED
	 * @returns
	 */
	clearAll() {
		return this.clearLeadContact().clearProjects();
	}

	/**
	 * DEPRECATED
	 * @returns
	 */
	clearLeadContact() {
		return this;
	}

	clearProjects() {
		this._leadProjects = {};
		Cookies.remove(COOKIE_NAMES.PROJECTS, $.extend({}, this._cookieDomainArg));
		return this;
	}

	/**
	 * Did the user already request docs for this project.
	 * @param {number} projectId
	 * @returns {boolean}
	 */
	hasRequestedProjectAccess(projectId) {
		projectId = +projectId;
		return !!this._leadProjects[projectId]?.reqAccess; // value is numeric timestamp ms.
	}

	/**
	 * Called when page has been loaded.
	 * @returns {LeadModel}
	 */
	initialize() {
		return this.reload();
	}

	isLeadClientValid() {
		const lc = this.leadContact;
		let result = true;
		['name', 'email', 'phone'].forEach((name) => {
			const validator = REQUEST_ACCESS_FORM_VALIDATORS[name];
			if (validator && !validator(lc[name])) {
				result = false;
			}
		});
		return result;
	}

	/**
	 * Load the model from cookies.
	 * @returns {LeadModel}
	 */
	reload() {
		this._leadProjects = this._loadLeadProjects();
		return this;
	}

	/**
	 * Set the client-side stored contact information for the lead.
	 * @param {LeadContact} leadContact
	 * @returns LeadModel
	 */
	setLeadContact(leadContact) {
		leadContact ??= {};
		const contactInfoRequest = {};
		[['email', 'EmailAddress'], ['name', 'DisplayName'], ['phone', 'PhoneNumber']].forEach((key) => {
			const val = leadContact[key[0]];
			if (val && ('string' === typeof(val))) { contactInfoRequest[key[1]] = val; }
		});
		this._session.updateContact(contactInfoRequest);
		return this;
	}

	/**
	 * Mark the specified project as having had access requested by the user.
	 * @param {number} projectId
	 * @returns {LeadModel}
	 */
	setHasRequestedProjectAccess(projectId) {
		projectId = +projectId;
		const projects = this._loadLeadProjects();
		if (!projects[projectId] || ('object' !== typeof(projects[projectId]))) {
			projects[projectId] = { reqAccess: Date.now() };
		} else {
			projects[projectId].reqAccess = Date.now();
		}
		this._leadProjects = projects;
		Cookies.set(COOKIE_NAMES.PROJECTS, JSON.stringify(projects), $.extend({ expires: 3650 }, this._cookieDomainArg));
		return this;
	}

	/**
	 * Load lead-related cookie'd project info from the cookie.
	 * @returns {object.<number,LeadProject>} projects
	 */
	_loadLeadProjects() {
		try {
			const projects = JSON.parse(Cookies.get(COOKIE_NAMES.PROJECTS));
			if (('object' === typeof (projects)) && (null !== projects)) {
				return projects;
			}
		} catch (_e) {
		}
		return {};
	}
}

export { LeadModel }
