import moment from 'moment';

class ChatController {
	constructor(options) {
		this._callosumClient = options.callosumClient;
		this._alertOnReceivedMessages = !!options.alertOnReceivedMessages;
		this._location = options.location || {};
		this._model = options.model;
		this._settings = options.settings || {};
		this._view = options.view;
		this._view.onSendMessage((message) => {
			this._sendMessage(message);
		}, { synchronous: true });
	}

	get model() {
		return this._model;
	}

	handleContentIdChanged(contentId) {
		console.debug('[chat]', 'ContentId changed', contentId?.toString(), Date.now() * .001);
		return this;
	}

	handleRoomChanged() {
		console.log('[chat]', 'Room changed', this._model.callosumChannelId, Date.now() * .001);
		if (this._model.callosumChannelId) {
			!this._callosumClient.isConnected ?
				this._callosumClient.Connect(this._model.callosumChannelId).catch(console.error) :
				this._callosumClient.SetChannel(this._model.callosumChannelId).catch(console.error);
		} else if (this._callosumClient.isConnected) {
			this._callosumClient.Disconnect();
		}
		return this;
	}

	handleRoomLoaded(roomId, contentId) {
		this._model.roomId = roomId;
		this._model.contentId = contentId;
		return this;
	}

	initialize() {
		this._initializeCallosumClient();
		this.handleRoomChanged();
		this._model
			.onContentIdChanged((contentId) => { this.handleContentIdChanged(contentId); }, { synchronous: true })
			.onRoomChanged((roomId) => { this.handleRoomChanged(roomId); }, { synchronous: true });
		this._view.initialize();
		return this;
	}

	get _messageFormattedTimestamp() {
		return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
	}

	_initializeCallosumClient() {
		const settings = this._settings.Callosum;
		if (settings.HeartbeatIntervalMs) { this._callosumClient.heartbeatInterval = settings.HeartbeatIntervalMs; }
		if (settings.Debug) { this._callosumClient.debug.enabled = settings.Debug; }
		return this._initializeCallosumReceivedMessageHandler()
			._initializeCallosumReceivedSystemMessageHandler()
			._initializeCallosumReceivedApplicationEventHandler();
	}

	_initializeCallosumReceivedApplicationEventHandler() {
		this._callosumClient.on('ReceivedApplicationEvent', (_fromClientInstanceId, eventTopic, data) => {
			if ('RespondableMessage' !== eventTopic) {
				return;
			}

			const chatMessageShim = {
				data: data.messageObj,
				fromParticipantId: data.fromParticipantId,
				isFromMyClient: this._callosumClient.clientInstanceId === data.fromClientInstanceId,
				toParticipantId: data.toParticipantId
			}
			this._model.addReceivedRespondableMessage(chatMessageShim);
			this._alertOnReceivedMessages && this._view.alertNewChatMessage(chatMessageShim);
		});
		return this;
	}

	_initializeCallosumReceivedMessageHandler() {
		this._callosumClient.on('ReceivedMessage', (_fromClientInstanceId, messageObj) => {
			const chatMessageShim = {
				data: messageObj,
				fromParticipantId: messageObj.participantId
			};
			this._model.addReceivedMessage(chatMessageShim);
			this._alertOnReceivedMessages && this._view.alertNewChatMessage(chatMessageShim);
		});
		return this;
	}

	_initializeCallosumReceivedSystemMessageHandler() {
		this._callosumClient.on('ReceivedSystemMessage', (_fromClientInstanceId, messageType, messageObj) => {
			if (['AutoResponse', 'PresentationChatMessage'].includes(messageType)) {
				const chatMessageShim = {
					data: messageObj,
					fromParticipantId: messageObj.participantId
				};
				this._model.addReceivedMessage(chatMessageShim);
				if ('PresentationChatMessage' === messageType) {
					this._alertOnReceivedMessages && this._view.alertNewChatMessage(chatMessageShim);
				}
			}
		});
		return this;
	}

	_sendMessage(message) {
		const sceneParams = this._model.currentSceneChatParameters;
		const data = {
			tourId: this._model.contentId.id,
			contentId: this._model.contentId.id,
			contentType: this._model.contentId.type,
			vlookat: sceneParams.vlook,
			hlookat: sceneParams.hlook,
			chatText: message,
			contentPath: sceneParams.contentPath,
			createdDateTime: this._messageFormattedTimestamp,
			name: this._model.myDisplayName,
			participantId: this._model.myParticipantId,
			thumbUrl: sceneParams.thumbnailUrl,
			isPublicRoom: true,
			chatRoomId: this._model.roomId,
			scapeCastId: `chat${this._model.roomId}`,
			fov: sceneParams.fov,
			sceneName: 'irrelevant',
			isChatOnlyParticipant: false
		};
		const chatMessageShim = {
			data: data,
			fromParticipantId: this._model.myParticipantId
		};
		this._model.addOutgoingMessage(chatMessageShim).then((uniqueId) => {
			data.uniqueId = uniqueId;
			this._callosumClient.SendMessage(data).catch(console.error);
		});
		return this;
	}

	_sendRequestCall(message) {
		const myDisplayName = this._model.myDisplayName;
		const myParticipantId = this._model.myParticipantId;
		const data = {
			participantId: myParticipantId,
			contentId: this._model.contentId.id,
			contentType: this._model.contentId.type,
			name: myDisplayName,
			chatText: message || 'Would you like me to join you?',
			createdDateTime: this._messageFormattedTimestamp,
			chatRoomId: this._model.roomId,
			chatQuestion: {
				DefaultOptionId: 0,
				Message: `Allow ${myDisplayName} to join you?`,
				Options: [
					{
						Id: 0,
						Label: 'Yes',
						ActionType: 'NegotiateJoinRespondAffirmative',
						ActionData: {
							replyToParticipantId: myParticipantId
						}
					},
					{
						Id: 1,
						Label: 'No',
						ActionType: 'NegotiateJoinRespondNegative',
						ActionData: {
							replyToParticipantId: myParticipantId
						}
					},
				]
			}
		};
		const respondableMessage = {
			toParticipantId: null,
			fromParticipantId: myParticipantId,
			fromClientInstanceId: this._callosumClient.clientInstanceId,
			messageObj: data
		};
		this._callosumClient.SendApplicationEventAll('RespondableMessage', respondableMessage).catch(console.error);
		return this;
	}
}

export { ChatController }
