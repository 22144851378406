

class ContentModelSimulator {
	constructor(viewerModel) {
		this._viewerModel = viewerModel;
	}
	get content() {
		return EveryScape.SyncV2.initializationData.Campaign?.Project || EveryScape.SyncV2.initializationData.Project;
	}

	get contentId() {
		return {
			id: EveryScape.SyncV2.ContentId,
			type: EveryScape.SyncV2.ContentType
		};
	}

	get isSplashScreenEnabled() {
		return !!(this._viewerModel?.isSplashScreenEnabled);
	}

	getContentName() {
		const initData = EveryScape.SyncV2.initializationData;
		return initData?.Campaign?.Label || initData?.Project?.Label;
	}

	_getContentItemFromListing(contentPath, listing) {
		const lciIndex = listing?.ListingContentItems?.findIndex(lci => lci.ContentId === contentPath);
		if (lciIndex >= 0) {
			return listing.ListingContentItems[lciIndex];
		} else {
			const csiIndex = listing?.ListingContentItems?.findIndex(lci => lci.ContentSetItem?.ContentPath == contentPath);
			if (csiIndex >= 0) {
				return listing.ListingContentItems[csiIndex];
			}
		}
		return null;
	}

	getSelectedContentItem() {
		const currentListing = this.getSelectedListing();
		const contentPath = EveryScape.SyncV2.CurrentState.telemetrySelf.content;

		let contentItem = this._getContentItemFromListing(contentPath, currentListing);
		if (contentItem) {
			return contentItem;
		} else {
			return this._getContentItemFromListing(contentPath, EveryScape.SyncV2.ListingContentMap.get(contentPath));
		}
	}

	getSelectedListing() {
		return EveryScape.SyncV2.CurrentState.telemetrySelf.currentPlace;
	}

}

export { ContentModelSimulator }