/**
 * @file Utilities specific to browser functionality.
 */

const iosPlatforms = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

/**
 * Test if the browser is running on an iOS device or tablet.
 * @return {boolean}
 */
function isIos() {
	// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
	return iosPlatforms.includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes('Mac') && ('ontouchend' in document));
}

/**
 * Tests if the browser is running on a mobile device or tablet.
 * @return {boolean}
 */
function isMobileDevice() {
	return !!navigator.userAgent.match(/(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i) || isIos();
}

export { isIos, isMobileDevice }
