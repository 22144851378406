import $ from 'jquery';
import { normalizeGuid } from './utils/utils.es13';

const CONTENT_TYPE_JSON = 'application/json; charset=utf-8';
const DEFAULT_API_ARGS = { contentType: CONTENT_TYPE_JSON, dataType: 'json', method: 'POST' };
const DEFAULT_API_ARGS_GET = $.extend({}, DEFAULT_API_ARGS, { method: 'GET' });

class XhrError extends Error {
	constructor(jqXHR, textStatus, errorThrown) {
		super(errorThrown);
		this.errorThrown = errorThrown;
		this.jqXHR = jqXHR;
		this.name = 'XhrError';
		this.textStatus = textStatus;
	}
}

/**
 * Infinityy Room API
 */
class IrApi {
	constructor(options) {
		/** @member string */
		this._aiServiceUrl = options.aiServiceUrl;
		/** @member string */
		this._rootUrl = options.rootUrl;
		/** @member IrSession */
		this._session = options.session;
	}

	addPreProjectImage(projectGuid, imageUrl) {
		return new Promise((resolve, reject) => {

			$.ajax(`${this._rootUrl}/api/self-service/add-image?authToken=${this._session.authToken}&projectGuid=${projectGuid}`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify({ url: imageUrl }) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	checkout(desiredSubscription) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/checkout?` + $.param(params),
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(desiredSubscription) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	createOrganization(name) {
		return new Promise((resolve, reject) => {
			const params = $.extend({}, this._getDefaultParams(), { name: name });
			$.ajax(`${this._rootUrl}/api/org?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, { method: 'PUT' }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	createPreProject() {
		return new Promise((resolve, reject) => {

			$.ajax(`${this._rootUrl}/api/self-service/create-preproject?authToken=${this._session.authToken}`,
				$.extend({}, DEFAULT_API_ARGS, {
					contentType: CONTENT_TYPE_JSON,
					dataType: 'json'
				}))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	editOrganizationProperty(organizationProperty) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/property?` + $.param(params),
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(organizationProperty) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	editOrganizationUser(organizationUser) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/user?` + $.param(params),
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(organizationUser) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	finalizePreProject(projectGuid) {
		return new Promise((resolve, reject) => {

			$.ajax(`${this._rootUrl}/api/self-service/finalize?authToken=${this._session.authToken}&projectGuid=${projectGuid}`,
				DEFAULT_API_ARGS)
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	findResoProperties(address) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/v4/reso/find-property`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify({ address: address }) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	/**
	 * Get Page Initialization Data
	 * @returns {Promise}
	 */
	getPageInitializationData() {
		const initialUrl = window.pageInitialization.initialUrl;
		const msg = {
			clientToken: this._session.clientTokenV4,
			roomId: initialUrl.chatId,
			slug: initialUrl.slug
		}

		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/v4/viewer/init`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(msg) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data.result);
					}
				});
		});
	}

	getChatroom(chatroomId) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${ScapeKitRootURL}/api/chatrooms/${chatroomId}/summary?` + $.param(params), DEFAULT_API_ARGS_GET)
				.done(roomData => {
					Infinityy.BrandHome.AddChatIfUnique(roomData);
					resolve(roomData);
				});
		});
	}

	getOrganization(organizationId) {
		return new Promise((resolve, reject) => {
			let params = this._getDefaultParams();
			if ((null !== organizationId) && !isNaN(+organizationId)) {
				params.organizationId = +organizationId;
			}
			$.ajax(`${this._rootUrl}/api/org?` + $.param(params), DEFAULT_API_ARGS_GET)
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	getPreloadContent(slug) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/v3/viewercontent/preload?${$.param({ q: slug })}`, DEFAULT_API_ARGS_GET)
				.done((data) => {
					data.CampaignGuid = normalizeGuid(data.CampaignGuid);
					data.ProjectGuid = normalizeGuid(data.ProjectGuid);
					resolve(data || {});
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	getPreProject(projectGuid) {
		return new Promise((resolve, reject) => {

			$.ajax(`${this._rootUrl}/api/self-service/get-project?authToken=${this._session.authToken}&projectGuid=${projectGuid}`,
				DEFAULT_API_ARGS_GET)
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	getSlug(contentType, contentId /* numeric ID */) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/v3/get-slug/${contentType}/${contentId}`,
				DEFAULT_API_ARGS_GET)
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}
	inviteUser(p) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/invite-user?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(p) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	regenerateDescription(longDescription, shortDescription, instructions) {
		return new Promise((resolve, reject) => {
			$.ajax(this._aiServiceUrl + 'api/v0/', $.extend({}, DEFAULT_API_ARGS, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'regenerate-description',
					id: Math.random(),
					params: {
						longDescription,
						shortDescription,
						instructions
					}
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			})
			.done((data, status, jqXhr) => {
				if (!data?.result?.description) {
					reject(jqXhr, status);
				} else {
					resolve(data.result?.description);
				}
			});
		});
	}

	setPreProjectAddress(projectGuid, address) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/self-service/set-address?authToken=${this._session.authToken}&projectGuid=${projectGuid}`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify({ address: address })}))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	setPreProjectAiInstructions(projectGuid, instructions) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/self-service/set-ai-instructions?authToken=${this._session.authToken}&projectGuid=${projectGuid}`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(instructions) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	setPreProjectGroups(projectGuid, groups) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/self-service/set-groups?authToken=${this._session.authToken}&projectGuid=${projectGuid}`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(groups) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	setPreProjectStructuredAttributes(projectGuid, attributes) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/self-service/set-structured-attributes?authToken=${this._session.authToken}&projectGuid=${projectGuid}`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(attributes) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	updateOrganizationAccountInfo(accountInfo) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/account-info?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, {data: JSON.stringify(accountInfo)}))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	updateProjectExtraJson(projectId, extraJson) {
		return new Promise((resolve, reject) => {
			const params = $.extend({}, this._getDefaultParams(), { projectId: projectId });
			$.ajax(`${this._rootUrl}/api/project/extraJson?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(extraJson) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	wakeSelfService() {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._aiServiceUrl}self-service/wake`, $.extend({}, DEFAULT_API_ARGS, { dataType: 'text' }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	_getDefaultParams() {
		return { authToken: this._session.authToken };
	}
}

export { IrApi }
