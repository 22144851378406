import { getAuthToken, getCurrentListingId, getCurrentListingContentItems, getCurrentListingUrls, getCurrentOrDefaultDisplayName, getCurrentTelemetry } from "../utils/legacy-utils.es12"
import { ViewerUrl } from './viewer-url.es13.js'

const LCI = "ListingContentItem";
const LURL = "ListingUrl";

function areContentItemsTheSame(a, b) {
	if (!a && !b) {
		return true;
	} else if (a && b) {
		return (a.contentReferenceType === b.contentReferenceType) && (a.contentReferenceId === b.contentReferenceId);
	} else {
		return false;
	}
}

/**
 * Controller for the viewer page.
 * WIP wrapping and migrating functionality from legacy code.
 */
class ViewerController {
	constructor(options) {
		this._activityMonitor = options.activityMonitor;
		this._ai = options.ai;
		this._aiService = options.aiService;
		this._api = options.api;
		this._chatController = options.chatController;
		this._irApi = options.irApi;
		this._leadModel = options.leadModel;
		this._model = options.model;
		this._session = options.session;
		this._view = options.view;
		this._url = window.pageInitialization.initialUrl.cloneWithoutTemporaryParams();
		this._initialLoadStages = {
			contentLoaded: false,
			userIdentified: false
		}

		// event handling
		this._view.onFeatureResequence = (evt, ui) => { this._onFeatureResequence(evt, ui) };
		this._view.onOpenDashboard = (_evt) => { this.openDashboard(); };
		this._view.onLeadCaptureDialogClose = () => {
			this._activityMonitor.contactFormDataSaved();
			this._activityMonitor.leadCaptured();
			this.handleUserIdentifyDialogFinished();
		};
	}

	getLeadBeforeSendingChat(chatCallback) {
		this._view.getLeadBeforeSendingChat(chatCallback);
		return this;
	}

	// Hack for now. Called in loadListingInViewer. Right now this function will update the selected content item and listing and the location bar.
	handleLciUpdated(lci) {
		if (lci) {
			const ci = this._model.contentItems.find((el) => (el.contentReferenceType === 'ListingContentItem') && (el.contentReferenceId == lci.Id));
			if (ci) {
				this._model.setSelectedContentItem(ci)
					.setSelectedListingId(ci.listingId);
				this._url = this._url
					.setListingContentItemId(ci?.contentReferenceId)
					.setListingId(ci.listingId);
				this._updateLocationBar();
			}
		}
		return this;
	}

	handleUserAuthenticated(data) {
		data?.ProjectRoles?.forEach(pr => {
			const roleName = (pr?.Role?.toLowerCase() || '').split(',');
			if (roleName.includes('teamadmin')) {
				const projectGuid = pr?.Project?.PublicId;
				if (projectGuid) {
					this._model.userProjectAdminGuids.push(projectGuid);
				}
			}
		});

		this._updateViewForUserAndProject();

		return this._handleUserIdentificationComplete();
	}

	handleFirstUserInteraction(evt) {
		if (!this._model.userHasInteracted) {
			this._model.userHasInteracted = true;
			this._activityMonitor.userInteracted(evt.type, evt.target);
		}
	}

	initializeInteractionBodyEvents() {
		const events = ['click', 'touchstart', 'keydown'];
		let blurHandler, handler;

		// Once any of these events happens, clean up the event listeners. We only want the first one.
		function cleanup() {
			events.forEach((e) => { document.body.removeEventListener(e, handler, true) });
			window.removeEventListener('blur', blurHandler, true);
		}

		// The events that definitely happen in the current window get a simple handler.
		// Note that we attach using the "capturing" flag to addEventListener, so that
		// we can see events from all targets in the page.
		handler = (evt) => {
			// only respond to user-initiated events
			if (evt?.isTrusted) {
				this.handleFirstUserInteraction(evt);
				cleanup();
			}
		};
		events.forEach((e) => { document.body.addEventListener(e, handler, true) });

		// In order to detect user interactions with content in a cross-site iframe, we need
		// to listen for the blur event on the main window, but test to make sure the newly
		// active element is an iframe.
		blurHandler = () => {
			if (Array.from(document.querySelectorAll('iframe')).includes(document.activeElement)) {
				this.handleFirstUserInteraction({ type: 'focus', target: document.activeElement });
				cleanup();
			};
		}
		window.addEventListener('blur', blurHandler, true);

		// Force focus on the current window, so that if the first interaction is inside an iframe
		// the blur listener will activate
		window.focus();
	}

	initialize() {
		this._leadModel.initialize();
		this._view.initialize();
		this.initializeInteractionBodyEvents();
		return this._updateLocationBar()._initializeUrlMonitor();
	}

	/**
	 * Happens after content load. Sets selected content item (if the URL specified one).
	 * @returns
	 */
	initializeView(data) {
		this._chatController.handleRoomLoaded(this._model.chatId, this._model.contentId);
		const initialUrl = window.pageInitialization.initialUrl;
		let sceneContentPath, sceneParams, contentItem, listing;
		if (initialUrl.contentItemId) {
			contentItem = this._model.getContentItem(initialUrl.contentItemId);
			if (contentItem) {
				listing = this._model.getListing(contentItem.listingId);
			}
		}

		if (!listing && initialUrl.listingId) {
			listing = this._model.getListing(initialUrl.listingId);
		}
		if (!listing) {
			listing = this._model.getListing(EveryScape.SyncV2.ContentDisplayOrder.ProjectDefaultListingId);
		}
		if (!listing) {
			if (data.Campaign) {
				listing = this._model.getListing(data.Campaign.Project?.Listings[0]);
			} else if (data.Project) {
				listing = this._model.getListing(data.Project?.Listings[0]);
			} else {
				listing = data.Listing;
			}
		}

		if (!contentItem) {
			contentItem = this._model.contentItems.find((ci) => (ci.listingId === listing?.Id));
		}
		if (!contentItem) {
			contentItem = this._model.contentItems[0];
		}

		// URL always takes precedence on scene.
		if (initialUrl.sceneContentPath && !initialUrl.sceneContentPath.startsWith('overlay')) {
			sceneContentPath = initialUrl.sceneContentPath;
			sceneParams = initialUrl.sceneParams;
		} else if ('ListingContentItem' === contentItem?.contentReferenceType) {
			sceneContentPath = contentItem?.contentPath;
			sceneParams = contentItem?.position;
		} else { // Find _something_ to load in the viewer
			let sceneContentItem = this._model.contentItems.find((ci) => (ci.listingId === listing?.Id) && ('ListingContentItem' === ci.contentReferenceType));
			if (!sceneContentItem) {
				sceneContentItem = this._model.contentItems.find((ci) => ('ListingContentItem' === ci.contentReferenceType));
			}
			sceneContentPath = sceneContentItem?.contentPath;
			sceneParams = sceneContentItem?.position;
		}

		console.debug('[init]', 'Loading view to listing.', `listing:${listing?.Id}`,
			`${contentItem?.contentReferenceType}:${contentItem?.contentReferenceId}`,
			sceneContentPath, sceneParams, Date.now() * .001);
		EveryScape.SyncV2.UI.loadMainMeViewer(EveryScape.SyncV2.ContentId, EveryScape.SyncV2.ContentType);
		this._normalizeContentPath(sceneContentPath).then((contentPath) => {
			EveryScape.SyncV2.loadListingInViewer(listing,
				{ contentPath: contentPath, position: sceneParams });
			this.setContentItem(contentItem, { updateContentArea: true, updateScene: false });
		})
		return this;
	}

	initializeUnauthenticatedUser() {
		return this._handleUserIdentificationComplete();
	}

	handleContentLoaded() {
		this._initialLoadStages.contentLoaded = true;
		this._sendEventIfContentVisible();

		if (this._initialLoadStages.contentLoaded && this._initialLoadStages.userIdentified) {
			EveryScape.SyncV2.UI.showSplashModals();
		}
	}

	handleUserIdentifyDialogFinished() {
		this._initialLoadStages.userIdentified = true;
		this._sendEventIfContentVisible();

		if (this._initialLoadStages.contentLoaded && this._initialLoadStages.userIdentified) {
			EveryScape.SyncV2.UI.showSplashModals();
		}
	}

	_sendEventIfContentVisible() {
		if (this._initialLoadStages.contentLoaded && this._initialLoadStages.userIdentified) {
			this._activityMonitor.contentVisible();
		}
	}

	onSendingTelemetry(content, viewerPosition) {
		this._url = this._url.setSceneContentPath(content?.contentPath).setSceneParams(viewerPosition);
		return this._updateLocationBar();
	}

	openDashboard() {
		let url = `${ScapeKitRootURL}/InfinityyDashboard/BrandHome`;
		if (this._model.projectGuid) {
			const params = {};
			if (this._model.projectGuid) {
				params.contentId = this._model.projectGuid;
				params.contentType = 'project';
			}
			if (this._model.chatId) {
				params.chatId = this._model.chatId;
			}
			if (Object.keys(params).length) {
				url += '?' + $.param(params);
			}
		}
		window.open(url, 'dashboard');
		return this;
	}

	refreshAfterEditModeChange() {
		console.debug('[content-area]', 'Refreshing view and model after edit mode change.', Date.now() * .001);
		this.refreshFromLegacyModel()
			.setContentItem(this._model.getSelectedContentItem());
		return this;
	}

	refreshFromLegacyModel() {
		this._model.refreshFromLegacyModel();
		return this._updateViewForUserAndProject();
	}

	selectNextContentItem() {
		return this.setContentItem(this._model.getNextContentItem());
	}

	selectPreviousContentItem() {
		return this.setContentItem(this._model.getPreviousContentItem());
	}

	/**
	 * Update content area to reflect this contentItem if necessary or required.
	 * Update the scene if necessary or required.
	 * @param {object} contentItem
	 * @param {object} options
	 * @param {boolean} options.updateContentArea Boolean, not truthy. If true, update content area. If false DO NOT. Otherwise use business logic.
	 * @param {boolean} options.updateScene Boolean, not truthy. If true, update scene. If false - do NOT update scene. Otherwise use some business logic to decide.
	 * @returns
	 */
	setContentItem(contentItem, options) {
		const oldContentItem = this._model.getSelectedContentItem();
		this._model.setSelectedContentItem(contentItem);

		// update info widget and feature list portion of sidebar.
		if (false !== options?.updateContentArea) {
			EveryScape.SyncV2.UI.ContentItemListManager.render(contentItem);
		}
		const contentItemHasScene = ('ListingContentItem' === contentItem?.contentReferenceType);
		const contentItemChanged =  !areContentItemsTheSame(oldContentItem, contentItem);
		const contentPathChanged = contentItemHasScene &&
			(EveryScape.SyncV2.CurrentState?.telemetrySelf?.content !== contentItem?.contentPath);
		if (contentItemHasScene && (false !== options?.updateScene)) {
			if ((true === options?.updateScene) ||
				(contentItemChanged || contentPathChanged))
			{
				this._updateSceneForContentItem(contentItem);
			}
		}
		if (contentItem && (false !== options?.updateContentArea)) {
			const oldListing = this._model.getListing(oldContentItem?.listingId);
			const oldBuilding = this._model.buildings.get(oldListing?.PropertyId);
			const newListing = this._model.getListing(contentItem?.listingId);
			const newBuilding = this._model.buildings.get(newListing.PropertyId);
			if (newListing &&
				((true === options?.updateContentArea) || (oldListing?.Id !== newListing?.Id)))
			{
				if (newBuilding && (oldBuilding?.Id !== newBuilding?.Id)) {
					EveryScape.SyncV2.UI.updateBuildingUI(newBuilding);
				}
				this._view.setBuildingTabForSelectedListing();
				this._view.setSpaceTabForSelectedListing();
				console.debug('[content-area]', `Updating listing card details for listing:${newListing.Id} ${newListing.Name}`, Date.now() * .001);
				EveryScape.SyncV2.UI.updateUI(newListing);
			}
		}
		if (contentItem) {
			if ('ListingContentItem' === contentItem?.contentReferenceType) {
				this._url = this._url.setListingContentItemId(contentItem?.contentReferenceId);
				this._updateLocationBar();
			} else if ('ListingUrl' === contentItem?.contentReferenceType) {
				this._url = this._url.setListingUrlId(contentItem?.contentReferenceId);
				this._updateLocationBar();
			}
		}
		return this;
	}

	setContentBeforeLegacyModelUpdate(content) {
		const isFirstLoad = !this._model.projectId;
		this._model.setContentBeforeLegacyModelUpdate(content);
		if (isFirstLoad && this._model.isUserIdentificationComplete) {
			this._handleUserIdentificationAndProjectLoadComplete();
		}
		this._view.setContentLoaded();
		return this;
	}

	setNewChatId(chatId) {
		this._url = this._url.setChatId(chatId);
		return this._updateLocationBar();
	}

	setSelectedListingId(listingId) {
		this._model.setSelectedListingId(listingId);
		this._url = this._url.setListingId(listingId);
		return this._updateLocationBar();
	}

	setUserIsLoggedIn() {
		this._view.setUserIsAuthenticated();
		return this;
	}

	_onFeatureResequence(_, ui) {
		const startPos = ui.item.data('start_pos');
		const endPos = ui.item.index();
		const currentItem = ui.item[0];
		const listingId = getCurrentListingId();
		let updateObj = null;
		switch (currentItem.ContentReferenceType) {
			case "ListingContentItem":
				updateObj = {
					body: endPos,
					url: `${ScapeKitRootURL}/api/listings/${listingId}/contentItems/${currentItem.ContentReferenceId}/displaySequence?authToken=${getAuthToken()}`,
					method: "PUT"
				};
				break;
			case "ListingUrl":
				updateObj = {
					body: endPos,
					url: `${ScapeKitRootURL}/api/listings/${listingId}/urls/${currentItem.ContentReferenceId}/displayOrder?authToken=${getAuthToken()}`,
					method: "PUT"
				};
				break;
			default:
				console.error("Unknown Content Type");
				break;
		}
		if (updateObj !== null) {
			// TODO: Move this XHR call into a webpack'd utility class
			Place.Common.doJsonXHR(updateObj.method, updateObj.url, updateObj.body).then((result) => { this._handleFeatureOrderUpdate(result) });
		}
	}

	/**
	 * Return a map of listing content items and listing urls for the current listing.
	 * Key is composite ID (listingcontentitem:123) to the listingcontentitem or listingurl object.
	 */
	_getCurrentListingContentItems() {
		const map = new Map();
		getCurrentListingContentItems()
			.forEach((x) => {
				x.compositeId = `listingcontentitem:${x.Id}`;
				x.type = LCI;
				x.setDisplayOrder = (o) => { x.DisplaySequence = o };
				map.set(x.compositeId, x);
			});
		getCurrentListingUrls()
			.forEach((x) => {
				x.compositeId = `listingurl:${x.Id}`;
				x.type = LURL;
				x.setDisplayOrder = (o) => { x.DisplayOrder = o };
				map.set(x.compositeId, x);
			});
		return map;
	}

	/**
	 * Translate a displayorder list (Item1: type, Item2: id, Item3: order) to
	 * a map of compositeId to order.
	 */
	_getDisplayOrderMap(displayOrderUgly) {
		return new Map(displayOrderUgly.map((x) => [`${x.Item1.toLowerCase()}:${x.Item2}`, x.Item3]));
	}

	/**
	 * @param {Array.<{Item1: string, Item2: number, Item3: number}>} displayOrderUgly.
	 * TODO: fix displayOrderUgly to be a useful type spec.
	 */
	_handleFeatureOrderUpdate(displayOrderUgly) {
		const displayOrderMap = this._getDisplayOrderMap(displayOrderUgly);

		// composite id (listingcontentitem:1) to listingcontentitem or listingurl.
		const itemMap = this._getCurrentListingContentItems();

		for (const [compositeId, order] of displayOrderMap) {
			const item = itemMap.get(compositeId);
			if (item) {
				item.setDisplayOrder(order);
			}
		}

		this.refreshAfterEditModeChange();
	}

	/**
	 * Do things at the point when the project _and_ user have both been loaded (if necessary).
	 * @returns
	 */
	_handleUserIdentificationAndProjectLoadComplete() {
		if (this._model.aiEnabled) {
			this._ai.loadExistingChat();
			this._view.enableAi();
		}
		if (EveryScape.SyncV2.isUserIdentified()) {
			this._activityMonitor.updateUser();
		}
		if (this._model.isSplashScreenEnabled && !this._leadModel?.leadContact?.email) {
			this._view.showSplashLeadCaptureDialog();
			this._activityMonitor.contactFormShown();
		} else {
			this.handleUserIdentifyDialogFinished();
			const name = getCurrentOrDefaultDisplayName();
			EveryScape.SyncV2.updateName(name, true);
		}
		return this;
	}

	_handleUserIdentificationComplete() {
		if (!this._model.isUserIdentificationComplete) {
			this._model.isUserIdentificationComplete = true;
			this._view.handleUserIdentificationComplete();
			if (this._model.projectId) {
				this._handleUserIdentificationAndProjectLoadComplete();
			}
		}
		return this;
	}

	handleAiSplashDoNotShow(dontShow) {
		try {
			if (dontShow) {
				window.localStorage && window.localStorage.setItem('hideAISplash', 'true');
				this._activityMonitor.splashAiCloseDontShow();
			} else {
				window.localStorage && window.localStorage.setItem('hideAISplash', 'false');
			}
		}
		catch (e) {
			const msg = 'Error saving "Do Not Show Again" value';
			const dState = this._getDiagnosticState();
			dState.error = e;
			console.error(msg, e);
			EveryScape.SyncV2.sendSessionDiagnosticMessage(msg, dState);
		}
	}

	_getDiagnosticState() {
		const telemetry = getCurrentTelemetry();
		const diagnosticState = {
			chatId: this._model.chatId,
			clientInstanceId: window.clientInstanceId,
			contentPath: telemetry?.content?.contentPath,
			position: telemetry?.viewerPosition ?? [],
			participantId: window.clientInstanceId,
			clientTokenV4: this._session.clientTokenV4
		}
		return diagnosticState;
	}


	_initializeUrlMonitor() {
		window.addEventListener('hashchange', () => {
			const newUrl = window.location.href;
			const expectedUrl = this._url?.toString();
			if (expectedUrl !== newUrl) {
				this._url = new ViewerUrl(newUrl);
				if (this._url.sceneContentPath) {
					this._normalizeContentPath(this._url.sceneContentPath)
						.then((contentPath) => {
							EveryScape.SyncV2.UI.loadContentPathInMainViewer(contentPath,
								this._url.sceneParams, !!this._url.sceneParams);
						});
				}
			}
		});
		return this;
	}

	async _normalizeContentPath(contentPath) {
		if (contentPath?.length > 62 && contentPath.startsWith('matterport://')) {
			return await this._translateMatterportContentPath(contentPath);
		}
		return contentPath;
	}

	async _translateMatterportContentPath(contentPath) {
		const modelId = contentPath.substring(19, 30);
		const oldSweepId = contentPath.substring(37);
		try {
			const newSweepId = await this._irApi.getMapping('matterport', modelId, oldSweepId);
			return `matterport://model/${modelId}/sweep/${newSweepId}`;
		} catch (ex) {
			return null;
		}
	}

	_updateLocationBar() {
		const url = this._url.toString();
		if (url !== window.location.href) {
			window.history.replaceState(this._url, '', url);
		}
		return this;
	}

	_updateSceneForContentItem(contentItem) {
		if (contentItem?.contentPath) {
			EveryScape.SyncV2.UI.loadContentPathInMainViewer(contentItem.contentPath, contentItem.position, !!contentItem.position);
		}
		return this;
	}

	_updateViewForUserAndProject() {
		const isAdmin = this._model.userProjectAdminGuids.includes(this._model.projectGuid);
		window.IsBrandAccount = window.IsBrandAdmin = isAdmin;
		$('.hideIfNotBrandManager,.hideIfNotBrandAccount').toggleClass('hidden', !isAdmin);
		$('.hideIfBrandAccount').toggleClass('hidden', isAdmin);
		return this;
	}

}

export { ViewerController }
