import { ContentId } from '../models/content-id.es12';

/**
 * Encapsulation of chat message parameters on the back-end side (callosum).
 * @typedef {object} ChatMessageShim
 * @property {object} data
 * @property {string=} fromParticipantId
 * @property {bool=} isFromMyClient
 * @property {string=} toParticipantId
 */

/**
 * @classdesc A Shim between the legacy model/non-webpacked-javascript loaded by the brand dashboard
 * and the webpacked CrmDashboard* classes. So that unit testing the CrmDashboard* classes won't
 * require the entirety of the non-webpacked js loaded by the brand dashboard.
 */
class ChatLegacyShim {
	/**
	 * 
	 * @param {any} legacyScope Really the "window" object from brand dashboard.
	 */
	constructor(legacyScope) {
		this._legacyScope = legacyScope;
	}

	get contentId() {
		const contentId = this._legacyScope?.EveryScape?.SyncV2?.NumericContentId;
		const contentType = this._legacyScope?.EveryScape?.SyncV2?.ContentType;
		return ContentId.fromIdAndType(contentId, contentType);
	}

	get currentSceneChatParameters() {
		const viewer = this._legacyScope.EveryScape?.SyncV2?.CurrentState?.currentViewer;
		const viewerType = viewer.getCurrentViewerType();
		const position = this._translateViewerPositionToChat(viewer.getPosition(), viewerType);

		return {
			contentPath: viewer.getContent(),
			thumbnailUrl: viewer.getThumbnail(),
			hlook: position.hlook,
			vlook: position.vlook,
			fov: position.fov
		};
	}

	get myDisplayName() {
		return this._legacyScope.EveryScape?.SyncV2?.CurrentState?.self?.displayName;
	}

	get myParticipantId() {
		return this._legacyScope.EveryScape?.SyncV2?.CurrentState?.self?.participantId;
	}

	addOutgoingMessage(chatMessageShim) {
		const chatMessage = this.newChatMessage(chatMessageShim);
		return this._legacyScope.EveryScape.SyncV2.upsertChatItem(chatMessage).then((savedChatMessage) => {
			savedChatMessage.isOwner(true);
			this._legacyScope.EveryScape.SyncV2.UI.addGeneralMessage(this.myParticipantId, chatMessage);
			return new Promise((resolve) => { resolve(savedChatMessage.uniqueId()); });
		});
	}

	/**
	 * Add a received chat message to the local model.
	 * @param {ChatMessageShim} chatMessageShim
	 * @returns
	 */
	addReceivedMessage(chatMessageShim) {
		if (this.isMessageForMe(chatMessageShim)) {
			this.toggleChatControl("open");
		}
		const chatMessage = this.newChatMessage(chatMessageShim);
		this._legacyScope.EveryScape.SyncV2.UI.addGeneralMessage(chatMessageShim.fromParticipantId, chatMessage);
		return this;
	}

	/**
	 * Add a received respondable chat message to the local model.
	 * @param {ChatMessageShim} chatMessageShim
	 * @returns
	 */
	addReceivedRespondableMessage(chatMessageShim) {
		if (this.isMessageForMe(chatMessageShim)) {
			this.toggleChatControl("open");
		} else {
			chatMessageShim.data.chatQuestion = null;
		}

		const chatMessage = this.newChatMessage(chatMessageShim);
		if (chatMessageShim.isFromMyClient) {
			this._legacyScope.EveryScape.SyncV2.upsertChatItem(chatMessage);
		}

		if (chatMessageShim.fromParticipantId === this.myParticipantId) {
			chatMessage.askedQuestion(true);
		}

		this._legacyScope.EveryScape.SyncV2.UI.addGeneralMessage(chatMessageShim.fromParticipantId, chatMessage);
		return this;
	}

	/**
	 * Returns true if the message is one you should be shown, i.e.
	 * - directed to you specifically or to the group in general
	 * - and not authored by you 
	 * @param {any} chatMessageShim
	 */
	isMessageForMe(chatMessageShim) {
		if ((chatMessageShim.toParticipantId && (chatMessageShim.toParticipantId !== this.myParticipantId)) ||
			(chatMessageShim.fromParticipantId === this.myParticipantId))
		{
			return false;
		}
		return true;
	}

	getLeadWithCallback(callback) {
		if (!getSecurityToken() && this._legacyScope.page?.controller?.getLeadBeforeSendingChat) {
			this._legacyScope.page.controller.getLeadBeforeSendingChat(callback);
			return Promise.resolve();
		} else {
			return new Promise(callback);
		}
	}

	/**
	 * Creates a ChatDataModel from ChatMessageShim
	 * @param {ChatMessageShim} chatMessageShim
	 * @returns
	 */
	newChatMessage(chatMessageShim) {
		return new this._legacyScope.ChatDataModel(chatMessageShim.fromParticipantId, chatMessageShim.data);
	}

	setContentId(contentId, numericContentId, contentType) {
		this._legacyScope.contentId = contentId;
		this._legacyScope.numericContentId = numericContentId;
		this._legacyScope.contentType = contentType;
		return this;
	}

	/**
	 * Toggles the chat control to the indicated state or to the opposite of current state
	 * @param {any} desiredChatState "open", "closed", or leave null for true toggle
	 */
	toggleChatControl(desiredChatState) {
		this._legacyScope.EveryScape.SyncV2.UI.toggleChat(desiredChatState);
	}

	_translateViewerPositionToChat(position, viewerType) {
		let vlookat = 0;
		let hlookat = 0;
		let fov = 70;

		switch (viewerType) {
			case 'everyscape':
			case 'olio':
			case 'pdf':
			case 'streetview':
			case 'iguide':
				hlookat = position[0];
				vlookat = position[1];
				fov = position[2];
				break;
			case 'video':
				hlookat = position[0]; // time, in videos
				vlookat = 1; // playing, in videos, which we'll always set to 1 so chat message links play back
				fov = 0; // no relevant data here on videos
				break;
			case 'matterport':
				hlookat = position[4];
				vlookat = position[3];
				fov = position[5];
				break;
			default:
				console.log("Failed to get Position for viewerInstance " + instanceName);
				break;
		}
		return {
			hlook: hlookat,
			vlook: vlookat,
			fov: fov
		}
	}
}

export { ChatLegacyShim }
