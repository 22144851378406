const SCRIPT_ID = 'ga-script-tag';
class GAHandler {
	constructor(siteId) {
		window.dataLayer = window.dataLayer || [];
		this._ready = false;
		this._queue = [];
		this._siteId = siteId;
		this._enabled = !!siteId;
	}

	initialize() {
		this.gtagNow('js', new Date());
		this.gtagNow('config', this._siteId, { send_page_view: false });
		this._injectGAScript();
	}

	_injectGAScript() {
		if ($(`#${SCRIPT_ID}`).length) {
			return;
		}
		const script = $('<script>').attr({
			async: true,
			id: SCRIPT_ID,
			src: `https://www.googletagmanager.com/gtag/js?id=${this._siteId}`
		});
		$('HEAD').append(script);
	}

	gtagNow() {
		if (this._enabled) {
			const logParts = Array.from(arguments);
			logParts.unshift('[gtag]');
			console.debug.apply(this, logParts);
			window.dataLayer.push(arguments);
		}
	}

	gtag() {
		if (this._ready) {
			this.gtagNow.apply(this, arguments);
		} else {
			this._queue.push(arguments);
		}
	}

	recordPageView(name, location) {
		this.gtagNow('set', {
			page_title: name,
			page_location: location
		})
		this.gtagNow('event', 'page_view');
		this._ready = true;
		this._queue.forEach((i) => {
			this.gtagNow.apply(null, i);
		});
		this._queue = [];
	}
}

export { GAHandler }
