import { deepStripNullies } from '../utils/utils.es13.js';
import { SpeechToText } from '../speech/speech-to-text.es13.js';

class Hal9000 {
	constructor(options) {
		this._project = null;
		this._viewerModel = options.viewerModel;
		this._activityMonitor = options.activityMonitor;
		this._aiService = options.aiService;
		this._settings = options.settings;
		if (this._settings?.ai?.microphone?.enabled) {
			$('.hal9000-mic-button').each((_, el) => {
				const $mic = $(el);
				const $input = $(el).parent().find('.hal9000-input');
				$mic.data('stt', new SpeechToText({
					transcriptionOutputElement: $input,
					transcriptionDoneCallback: (result) => {
						this.handleUserInput(result);
						$input.val('');
					}
				}));
			});
		}
		this._$heyInfinityy = $('#HeyInfinityy');
		this._ttsCache = new Map();
		this._ttsEnabled = !!this._settings.ai?.tts?.enabled;
		this._autoTourEnabled = !!this._settings.autoTour?.enabled;
		this._autoTourDelayMs = 4500; // For empty captions or errors.
		this._setupAi();

		// TODO: remove this hack
		$('.beta-menu-item').on('click', (evt) => {
			// Menu Item
			const menuItemSelector = '.beta-menu-item';
			const menuItemSelectedClass = 'beta-menu-item-selected';
			const menuItem = $(evt.target).closest(menuItemSelector).get(0);
			$(menuItemSelector).removeClass(menuItemSelectedClass);
			$(menuItem).addClass(menuItemSelectedClass);

			// Panel Visibility
			const associatedSection = $(menuItem).attr('bmcid');
			const sectionSelector = `#${associatedSection}`;
			const sectionSelectedClass = 'beta-menu-content-selected';
			const panelCollapsedClass = 'beta-menu-content-collapsed';
			const $panel = $('#ContentUI');
			const panelVisible = !$panel.hasClass(panelCollapsedClass);

			if (associatedSection) {
				if (panelVisible && $(sectionSelector).hasClass(sectionSelectedClass)) {
					// we're clicking the one that's already selected
					$panel.addClass(panelCollapsedClass);
				} else {
					// we're clicking the one that's NOT already selected
					$panel.removeClass(panelCollapsedClass);
					$(sectionSelector).addClass(sectionSelectedClass);
					$(`.${sectionSelectedClass}:not(${sectionSelector})`).removeClass(sectionSelectedClass);
				}
			}
		});
	}

	async _heyInfinityy(message, $question) {
		const params = {
			message: message,
			projectGuid: this._project.PublicId,
			room: { id: this._viewerModel?.chatId },
			user: {
				identityId: window.page?.session?.identityId,
			},
			view: {
				featureId: this._viewerModel.getSelectedContentItem()?.contentReferenceId,
				listingId: this._viewerModel.getSelectedListing()?.Id,
				propertyId: this._viewerModel.getSelectedListing()?.PropertyId,
			}
		};
		const userOverrideModelId = $('#settings-model-selectbox').val();
		if (userOverrideModelId) {
			params.options = { modelId: userOverrideModelId };
		}
		let result = await this._aiService?.sendMessage(params);
		$question.data('messageId', result?.messageId);
		return this._handleAgentResult(result);
	}

	_handleAgentResult(result) {
		this._handleAgentMessage(result?.message, { answerId: result?.answerId });
		result?.functions?.forEach((f) => {
			let params = null;
			try {
				params = JSON.parse(f.params);
			} catch (_) { }
			switch (f?.name) {
				case 'applyNow':
				case 'bookTour':
					if (params?.url) {
						window.open(params.url, '_blank', 'noreferrer');
					}
					break;
				case 'invite':
					this._openInviteDialog(params);
					break;
				case 'contact':
					window.page.chatController._view._shim.toggleChatControl('open'); // WTF
					window.page.chatController._view._handleSendMessage("I'd like to speak to someone about this property.");
					break;
				case 'showMap':
					this._showMapWidget(params?.places);
					break;
			}
		});
		$('.hal9000-spinner.working').closest('.hal9000-answer').remove();
		return this;
	}

	_findFirstProperty(project) {
		return project?.Listings?.find((l) => l.PropertyUnit?.Property)?.PropertyUnit?.Property;
	}

	_autoTourNext() {
		switch (this._userSettings?.autoTour) {
			case 'ai':
				$('.next-button:not(.ff)').trigger('click');
				break;
			case 'auto':
				$('#CurrentItemNext').trigger('click');
				break;
		}
		return this;
	}

	_getTtsAudioUrl(text, voice) {
		const cacheKey = `${voice}:${text}`;
		if (this._ttsCache.has(cacheKey)) {
			return Promise.resolve(this._ttsCache.get(cacheKey));
		}
		return new Promise((resolve, reject) => {
			this._aiService.getTtsAudioUrl(text, voice).then((url) => {
				this._ttsCache.set(cacheKey, url);
				resolve(url);
			}).catch(() => {
				reject();
			});
		});
	}

	/**
	 * When the info widget caption changes, possibly do some tts.
	 * @param {any} caption
	 * @returns
	 */
	handleInfoCaptionChange(caption) {
		// this._ttsPlaying is either an AUDIO or a timer.

		if (!this._userSettings.narration) {
			return;
		}

		if (!caption) {
			if (this._ttsPlaying && this._ttsPlaying?.pause) { // Audio will move on after it's done.
				return this;
			}
			if (this._ttsPlaying) {
				clearTimeout(this._ttsPlaying);
			}
			this._ttsPlaying = setTimeout(() => { this._autoTourNext(); }, this._autoTourDelayMs);
			return this;
		}

		// Time to do TTS. Cancel any timer for empty captions.
		if (this._ttsPlaying && !this._ttsPlaying.pause) {
			clearTimeout(this._ttsPlaying);
			this._ttsPlaying = null;
		}

		// Moved (intentionally) from 1 captioned feature to another while voice was going.
		if (this._ttsPlaying?.pause && this._ttsPlayingCaption && (caption !== this._ttsPlayingCaption)) {
			this._ttsPlaying.pause();
			this._ttsPlaying = null;
			this._ttsPlayingCaption = null;
			this._$heyInfinityy.find('audio').remove();
		}

		if (this._ttsPlayingCaption === caption) {
			return this;
		}
		this._ttsPlayingCaption = caption;
		this._getTtsAudioUrl(caption, this._userSettings.voice).then((audioUrl) => {
			if ((caption !== this._ttsPlayingCaption) || this._ttsPlaying) {
				// Asynchronous operation (check that it's still applicable).
				return;
			}
			this._ttsPlaying = new Audio(audioUrl);
			this._$heyInfinityy.append(this._ttsPlaying);
			this._ttsPlaying.onended = () => {
				this._ttsPlaying = null;
				this._$heyInfinityy.find('audio').remove();
				this._autoTourNext();
			};
			this._ttsPlaying.play();
		}).catch(() => {
			if ((caption === this._ttsPlayingCaption) && !this._ttsPlaying) {
				this._ttsPlayingCaption = null;
				setTimeout(() => {
					this._autoTourNext();
				}, this._autoTourDelayMs);
			}
		});

		return this;
	}

	/**
	 * When the project is loaded, apply that seeding to the chat model.
	 * @param {any} project
	 */
	handleProjectLoaded(project) {
		this._project = deepStripNullies(JSON.parse(JSON.stringify(project)));
		this._initializeView();
		const property = this._findFirstProperty(project);
		let primaryPropertyType = 'commercial';
		switch (property?.PropertyTypeCode) {
			case 'RENT':
				primaryPropertyType = 'multifamily';
				break;
			case 'RES':
				primaryPropertyType = 'residential';
				break;
		}
		if (project?.Label?.startsWith('~Austin')) {
			primaryPropertyType = 'geo-portal';
		}
		if (property) {
			const l1 = [property?.Address1, property?.Address2].filter((el) => el).join(' ');
			const l2 = property?.Locality;
			const l3 = [property?.Region, property?.PostalCode].filter((el) => el).join(' ');
			const addr = [l1, l2, l3].filter((el) => el).join(', ');
			$('.hal9000-project-name').text(project.Name);
			$('.hal9000-address').text(addr);
			$('#hal9000-second-header').text(addr);
		}
		$('.hal9000-bubble').each((_, el) => {
			if ($(el).attr('property-types').split(',').map((pt) => pt.trim().toLowerCase())
				.includes(primaryPropertyType))
			{
				$(el).show();
			}
		});
		return this;
	}

	handleUserInput(userInput) {
		this._handleUserInput(userInput);
		return this;
	}

	_handleUserInput(userInput) {
		userInput = userInput?.trim() || '';
		if (!userInput) {
			return this;
		}
		this._activityMonitor.aiInteracted();
		console.debug('[hey-infinityy]', `You: ${userInput}`);

		// Toggle the initial section vs the second "answers" section.
		$('#hal9000-first').hide();
		$('#hal9000-second').show();

		if (userInput?.trim()?.toLowerCase() === 'debug') {
			window.page.controller.aiDebug = !window.page.controller.aiDebug;
			$('#HeyInfinityy').toggleClass('hal9000-debug', window.page.controller.aiDebug);
			return;
		}

		this._hideMapWidget();
		this._$log.find('.next-button').remove();
		let $avatar = $('<div class="hal9000-avatar">').append($('<div class="hal9000-you-avatar">').text('You'));
		let $userText = $('<div class="hal9000-question-text">').text(userInput);
		let $question = $('<div class="hal9000-question">').append($avatar).append($userText);
		this._$log.append($question);
		this._handleAgentMessage('Processing your query...', { processing: true });
		$('#hal9000-log-wrapper').scrollTop($('#hal9000-log-wrapper').get(0).scrollHeight);
		return this._heyInfinityy(userInput, $question);
	}

	_showMapWidget(places) {
		const $el = $('#ModalFindStreetviewPlace');
		$el.modal();
		$('#FindStreetviewPlaceSearchInput').val(places);
		$('#FindStreetviewPlaceSearchButton').trigger('click');
		$el.unbind('hidden.bs.modal').on('hidden.bs.modal', () => { Infinityy.StreetViewSearch.hide(); })
			.unbind('show.bs.modal').on('show.bs.modal', () => {
				Infinityy.StreetViewSearch.show();
			});
		return this;
	}

	_hideMapWidget() {
		try {
			$('#ModalFindStreetviewPlace').modal('hide');
		} catch (_) { }
		return this;
	}

	_getBadBot(r) {
		const problem = r?.problem;
		const hasProblem = ('undefined' !== typeof (problem));
		const $flag = $('<i class="material-icons hal9000-bad-bot-flag">').text('flag');
		const $button = $('<button class="hal9000-submit-problem-button">').text('submit');
		const $badBot = $('<div class="hal9000-bad-bot">')
			.toggleClass('hal9000-problem', hasProblem)
			.append($flag)
			.append($('<textarea class="hal9000-bad-bot-reason">').text(hasProblem ? problem : ''))
			.append($button);
		$button.on('click', (evt) => {
			const $bb = $(evt.target).closest('.hal9000-bad-bot');
			const messageId = $bb.closest('.hal9000-answer').data('messageId');
			const problem = $bb.find('textarea').val();
			this._aiService.recordProblem(messageId, problem);
			EveryScape.ToastrWrapper.displaySuccess('Problem report submitted');
		});
		$flag.on('click', (evt) => {
			const $bb = $(evt.target).closest('.hal9000-bad-bot');
			$bb.toggleClass('hal9000-problem');
			const messageId = $bb.closest('.hal9000-answer').data('messageId');
			if ($bb.hasClass('hal9000-problem')) {
				this._aiService.recordProblem(messageId, $bb.find('textarea').val());
				EveryScape.ToastrWrapper.displaySuccess('Problem reported');
			} else {
				this._aiService.recordProblem(messageId, null);
				EveryScape.ToastrWrapper.displaySuccess('Problem unreported');
			}
		});
		return $badBot;
	}

	_hookupAiFunctionClickHandlers($domElement) {
		// Old-style map-specific function call.
		// Now links come in with class= "ai-func" data-functionName="" data-params="JSON..."
		$domElement.find('.ai-func-map').off('click').on('click', (evt) => {
			let places = $(evt.target).closest('.ai-func-map').attr('data-places');
			this._showMapWidget(places);
		});

		$domElement.find('.ai-func').off('click').on('click', (evt) => {
			const $el = $(evt.target).closest('.ai-func');
			const functionName = $el.attr('data-functionName');
			let params = $el.attr('data-params');
			try { params = JSON.parse(params); } catch (_) { }
			switch (functionName) {
				case 'applyNow':
				case 'bookTour':
					window.open(params?.url, '_blank', 'noreferrer');
					break;
				case 'showMap':
					this._showMapWidget(params?.places);
					break;
			}
		});
	}

	loadExistingChat() {
		this.renderChatIntoElement(this._viewerModel.chatId, this._$log).then((resultCount) => {
			if (resultCount > 0) {
				$('#hal9000-first').hide();
				$('#hal9000-second').show();
				this._$log.find('.nextable').removeClass('nextable');
				this._$log.find('.lci').off('click').on('click', (evt) => {
					let lciId = +($(evt.target).closest('.lci').attr('lciid'));
					let ci = window.page.controller._model.getContentItem({ type: 'ListingContentItem', id: lciId });
					if (ci) {
						window.page.controller.setContentItem(ci, { updateContentArea: true, updateScene: true });
					}
				});

				this._hookupAiFunctionClickHandlers(this._$log);
				this._scrollToBottomOfLog();
			}
		});
		return this;
	}

	async renderChatIntoElement(chatId, element) {
		const response = await this._aiService.getChat(chatId);
		const $el = $(element);
		const resultCount = response?.result?.length ?? 0;
		response.result.forEach((r) => {
			if (r.question) {
				let $avatar = $('<div class="hal9000-avatar">').append($('<div class="hal9000-you-avatar">').text('You'));
				let $userText = $('<div class="hal9000-question-text">').text(r.question.userQuestion);
				let $question = $('<div class="hal9000-question">').append($avatar).append($userText);
				$question.data('messageId', r._id);
				$el.append($question);
			} else if (r.answer) {
				let $spinner = $('.hal9000-spinner').eq(0).clone();
				let $avatar = $('<div class="hal9000-avatar">').append(
					$('<div class="hal9000-ai-avatar">').append($spinner)
				);
				let $aiText = $('<div class="hal9000-answer-text">').html(r.answer)
					.append(this._getBadBot(r));
				let $answer = $('<div class="hal9000-answer">').append($avatar).append($aiText);
				$answer.data('messageId', r._id);
				$el.append($answer);
			}
		});
		return resultCount;
	}

	_openInviteDialog(args) {
		$('#MobileInviteBtn').trigger('click');

		let phone = args.phone;
		let email = args.email;
		const who = args?.who?.trim() || '';
		const isProbablyPhone = !!who.match(/^[0-9\(][0-9\-\.\) ]+$/);
		const isProbablyEmail = !!who.match(/^[\S]+@[\S]+\.[\S]+$/);

		if (isProbablyPhone) {
			phone = who;
		} else if (isProbablyEmail) {
			email = who;
		}
		if (phone) { $("#RecipientUsersPhoneNumber").val(phone); }
		if (email) { $("#RecipientUsersEmails").val(email); }

		const tabIndex = phone ? 2 : (email ? 3 : 1);
		Infinityy.CommonUI.switchTabsByIndex(tabIndex, "#InviteTabs", "#InviteSections");
		return this;
	}

	_handleAgentMessage(text, options) {
		text ||= '';
		console.debug('[hey-infinityy]', `Infinityy: ${text}`);
		this._lastAgentMessage = text;

		this._$log.find('.nextable').removeClass('nextable'); // Unhide previously hidden paragraphs.
		this._$log.find('.lci.cur').removeClass('cur'); // unhighlight the last link.
		this._$log.find('.next-button').remove(); // ditch the next button.

		let $spinner = $('.hal9000-spinner').eq(0).clone();
		if (options?.processing) {
			$spinner.addClass('working');
		}
		let $avatar = $('<div class="hal9000-avatar">').append(
			$('<div class="hal9000-ai-avatar">').append($spinner)
		);
		let $aiText = $('<div class="hal9000-answer-text">').html(text)
			.append(this._getBadBot());
		let $answer = $('<div class="hal9000-answer">').append($avatar).append($aiText);
		if (options?.answerId) { $answer.data('messageId', options.answerId); }

		this._$log.append($answer);

		this._revealParagraph(this._$log.find('.nextable:first'));
		let $responseBlock = this._$log.find('> .hal9000-answer:last');
		$responseBlock.find('.lci').off('click').on('click', (evt) => {
			let lciId = +($(evt.target).closest('.lci').attr('lciid'));
			let ci = window.page.controller._model.getContentItem({ type: 'ListingContentItem', id: lciId });
			if (ci) {
				window.page.controller.setContentItem(ci, { updateContentArea: true, updateScene: true });
			}
		});
		this._hookupAiFunctionClickHandlers($responseBlock);
		let $f = $responseBlock.find('.lci:first').trigger('click');

		if (text.match(/lciid/g)?.length > 1) {
			$f.addClass('cur');
			const $nextButton = $('<button class="next-button prevNextButton">Next<span class="material-icons">chevron_right</span></button>');
			const $ffButton = $('<button class="next-button ff prevNextButton"><span class="material-icons">double_arrow</span></button>');
			$aiText.append($nextButton);
			if ($responseBlock.find('p.nextable').length) {
				$aiText.append($ffButton);
			}

			/**
			 * Return a jquery list of all the subsequent LCIs in this response block (in all paragraphs).
			 * @param {any} $lci
			 * @returns
			 */
			const followingLcis = ($lci) => {
				const lciid = $lci?.attr('lciid');
				const $selectedLci = $lci || $responseBlock.find('.lci.cur');
				const $siblingLcis = $selectedLci.find('~ .lci');
				const $selectedParagraph = $selectedLci.parent();
				const $siblingParagraphs = $selectedParagraph.find('~ p.answer-paragraph');

				return $siblingLcis.add($siblingParagraphs.find('.lci'))
					.filter((_, el) => $(el).attr('lciid') !== lciid);
			};

			const highlightAndClickLci = ($lci) => {
				const $selectedLci = $responseBlock.find('.lci.cur');
				if ($lci.is($selectedLci)) {
					return;
				}
				const $allNextLcis = followingLcis($lci);
				$selectedLci.removeClass('cur');
				$lci.trigger('click');
				if ($allNextLcis.length) { // Don't highlight the last one.
					$lci.addClass('cur');
				}
			};

			const disableButtonsIfNecessary = () => {
				if (!followingLcis().length) {
					$nextButton.remove();
				}
				if (!($responseBlock.find('.nextable').length - (this._revealingParagraph ? 1 : 0))) {
					$ffButton.remove();
				}
			};

			const showNext = (fastForward) => {
				if (this._revealingParagraph) {
					return;
				}

				const $selectedLci = $responseBlock.find('.lci.cur');
				const $selectedParagraph = $responseBlock.find('p.answer-paragraph:not(.nextable):last');
				const $siblingLcis = $selectedLci.find('~ .lci');
				const $siblingParagraphs = $selectedParagraph.find('~ p.nextable');
				const $nextParagraph = $siblingParagraphs.eq(0);

				let moved = false;
				if (!fastForward && $siblingLcis.length) {
					const currentLciId = $selectedLci.attr('lciid');
					let i = 0;
					while ($siblingLcis.length > i) {
						const $sib = $siblingLcis.eq(i);
						const nextLciId = $sib.attr('lciid');
						if (nextLciId != currentLciId) {
							// In the same paragraph, move to the next LCI.
							highlightAndClickLci($sib);
							moved = true;
							break;
						} else {
							++i;
						}
					}
				}
				if (!moved && $nextParagraph.length) {
					// Might still be same LCI as previous link (in the case of "Next" vs ff), but it _does_
					// cause the paragraph to be exposed, so it's doing something.
					const $lcisInNextParagraph = $nextParagraph.find('.lci');
					if ($lcisInNextParagraph.length) {
						highlightAndClickLci($lcisInNextParagraph.eq(0));
					} else {
						const $newLci = $siblingLcis.eq(-1);
						if ($newLci.length) {
							highlightAndClickLci($newLci);
						}
					}
					this._revealParagraph($nextParagraph);
				}

				disableButtonsIfNecessary();

				this._scrollToBottomOfLog();
			};

			$nextButton.on('click', () => {
				showNext(false);
			});
			$ffButton.on('click', () => {
				showNext(true);
			});
		}
		this._scrollToBottomOfLog();
		return this;
	}

	_revealParagraph($element) {
		const html = $element.html();
		if (!html || this._revealingParagraph) {
			return;
		}
		this._revealingParagraph = true;
		const $p = $('<p>');

		$element.before($p);

		const plaintextLines = html.split("<br>")
			.map(l => l.replace(/(<([^>]+)>)/ig, ''));

		const forEachAsyncSequential = async (iterable, action) => {
			for (const x of iterable) {
				await action(x)
			}
		}

		const addLine = (line) => {
			return new Promise((resolve) => {
				const chars = line.split('');
				let index = 0;
				let iteration = 0;
				const interval = setInterval(() => {
					if (index < chars.length) {
						$p.html($p.html() + chars[index]);
					} else {
						$p.html($p.html() + '<br>');
						clearInterval(interval);
						this._scrollToBottomOfLog();
						resolve();
					}
					index++;
					if (0 == (++iteration % 15)) {
						this._scrollToBottomOfLog();
					}
				}, 10);
			});
		}

		forEachAsyncSequential(plaintextLines, addLine).then(() => {
			this._revealingParagraph = false;
			$p.remove();
			$element.removeClass('nextable');
		});
	}

	_scrollToBottomOfLog() {
		$('#hal9000-log-wrapper').scrollTop($('#hal9000-log-wrapper').get(0).scrollHeight);
		return this;
	}

	_initializeView() {
		this._$widget = $('#hal9000');
		this._$container = $('#hal9000-content');
		this._$log = $('#hal9000-log');
		this._$close = $('#hal9000-close');

		const micEnabled = !!this._settings.ai?.microphone?.enabled;
		const $aiInput = $('.hal9000-input');
		$aiInput.on('keyup', (evt) => {
			if (13 == evt.which) {
				const $input = $(evt.target);
				const userInput = $input.val().trim();
				if (userInput) {
					this.handleUserInput(userInput);
					$input.val('');
				}
			}
		});
		const $aiButtons = $('.hal9000-mic-button');
		$aiButtons.toggleClass('hal9000-input-send', !micEnabled);
		if (micEnabled) {
			$aiInput.on('focus', (evt) => {
				const $wrapper = $(evt.target).closest('.hal9000-input-wrapper');
				$wrapper.find('.hal9000-mic-button').addClass('hal9000-input-send').text('send');
			}).on('blur', (evt) => {
				const $wrapper = $(evt.target).closest('.hal9000-input-wrapper');
				$wrapper.find('.hal9000-mic-button').removeClass('hal9000-input-send').text('mic');
			});
			$aiButtons.text('mic');
		}
		$aiButtons.on('mousedown', (evt) => {
			const $el = $(evt.target);
			if (('mic' === $el.text()) && !this.recording) {
				this.recording = $el.data('stt');
				this.recording.record();
			}
		}).on('mouseup', (evt) => {
			const $el = $(evt.target);
			if ('mic' === $el.text()) {
				if (this.recording) {
					this.recording.stop();
					this.recording = false;
				}
			} else {
				const $input = $(evt.target).parent().find('.hal9000-input');
				this.handleUserInput($input.val());
				$input.val('');
			}
		});

		this._$close.on('click', () => {
			this._$widget.hide();
		});
		$('.hal9000-bubble').on('click', (evt) => {
			const $el = $(evt.target).closest('.hal9000-bubble');
			const text = $el.find('.hb2').text();
			this.handleUserInput(text);
		});

		// Hijack info widget
		this._$infoWidgetChatButton = $('#AskChatButton');
		this._$infoWidgetInput = $('#AskChat');
		this._$infoWidgetChatButton.off('click').on('click', () => {
			this.handleUserInput(this._$infoWidgetInput.val());
			this._$infoWidgetInput.val('');
		});
		this._$infoWidgetInput.off('keyup').on('keyup', (evt) => {
			if (13 == evt.which) {
				this.handleUserInput(this._$infoWidgetInput.val());
				this._$infoWidgetInput.val('');
			}
		});
		$('#CurrentItemHeader .hal9000-spinner').off('click').on('click', () => {
			$('.beta-menu-content').removeClass('beta-menu-content-selected');
			$('#HeyInfinityy').addClass('beta-menu-content-selected');
		});
		return this;
	}

	_renderSettingsModal() {
		this._userSettings.narration &&= this._ttsEnabled;
		this._$settingsNarrationSelectbox
			.attr('disabled', !this._ttsEnabled)
			.val('' + (this._ttsEnabled && this._userSettings.narration));

		if (!this._userSettings.narration) {
			this._userSettings.voice = null;
		}
		this._$settingsNarrationVoiceSelectbox
			.attr('disabled', !this._userSettings.narration)
			.val(this._userSettings.voice || '');

		const autoTourEnabled = this._ttsEnabled && this._userSettings.narration;
		if (!autoTourEnabled) {
			this._userSettings.autoTour = '';
		}
		this._$settingsAutoTourSelectbox
			.attr('disabled', !autoTourEnabled)
			.val(this._userSettings.autoTour);


		return this;
	}

	_setupAi() {
		this._$aiSettingsButton = $('.ai-settings-button');
		this._$aiSettingsModal = $('#settings-modal');
		this._$settingsNarrationSelectbox = $('#settings-narration-selectbox');
		this._$settingsNarrationVoiceSelectbox = $('#settings-narration-voice-selectbox');
		this._$settingsAutoTourSelectbox = $('#settings-auto-tour-selectbox');
		this._userSettings = {
			autoTour: '',
			narration: false,
			voice: null
		};
		if (this._ttsEnabled || this._autoTourEnabled) {
			this._$aiSettingsButton.show().on('click', () => {
				this._$aiSettingsModal.modal('show');
			});
			$('#settings-modal-close-button').on('click', () => {
				this._$aiSettingsModal.modal('hide');
			});
			this._$settingsNarrationSelectbox.on('change', () => {
				this._userSettings.narration = 'true' === this._$settingsNarrationSelectbox.val();
				this._renderSettingsModal();
			});
			this._$settingsNarrationVoiceSelectbox.on('change', () => {
				this._userSettings.voice = this._$settingsNarrationVoiceSelectbox.val();
				this._renderSettingsModal();
			});
			this._$settingsAutoTourSelectbox.on('change', () => {
				this._userSettings.autoTour = this._$settingsAutoTourSelectbox.val();
				this._renderSettingsModal();
			});
			this._renderSettingsModal();
		}
		return this;
	}
}

export { Hal9000 }
